import React from 'react';
// import { withRouter } from 'react-router-dom';

import classes from './Burger.module.css';
import BurgerIngredient from './BurgerIngredient/BurgerIngredient';

const burger = (props) => {
    let transformedIngredients = Object.keys(props.ingredients)
        .map(ingredientKey => {
            return [...Array(props.ingredients[ingredientKey])]
                .map((_, i) => {
                    return <BurgerIngredient key={ingredientKey + i} type={ingredientKey} />;
                });
        })
        .reduce((arr, element) => {
            return arr.concat(element);
        }, []);
    if (transformedIngredients.length === 0) {
        transformedIngredients = <p>Start adding ingredients!</p>
    }
    
    // console.log("Transform ingredients: ", transformedIngredients);
    
    return(
        <div className={classes.Burger} >
            <BurgerIngredient type="bread-top" />
                {transformedIngredients}
            <BurgerIngredient type="bread-bottom" />
        </div>
    );
};

/* Now we have access to the match, location, and history props through react-router */
// export default withRouter(burger);
export default burger;